import clsx from 'clsx';
import { checkmark } from 'ionicons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    IonButton,
    IonIcon
} from '@ionic/react';

import { Programs } from '../../constants';
import { ProgramId } from '../../models/ProgramId';
import { ProgramInfo } from './ProgramInfo';
import { ProgramMeal } from './ProgramMeal';

export const ProgramCard: React.FC<{
	program: keyof typeof Programs;
	loss: number;
	onSelect?: (v: ProgramId) => void;
}> = ({ program: p, loss, onSelect }) => {
	const program = Programs[p];
	const { t } = useTranslation();
	return (
		<div className='max-w-md bg-white md:mx-auto card' color='white'>
			<div className={clsx(program.color, 'section-header')}>Bước {p}</div>
			<ProgramInfo program={program} loss={loss} />
			<div className='section-content'>
				<ProgramMeal program={program} />
			</div>

			{onSelect && (
				<>
					<div className='section-footer'>
						<IonButton onClick={() => onSelect(p as string)}>
							<IonIcon icon={checkmark} slot='start' />
							{t('Select')}
						</IonButton>
					</div>
				</>
			)}
		</div>
	);
};
export default ProgramCard;
