import clsx from 'clsx';
import * as _ from 'lodash';
import React, { PropsWithChildren } from 'react';
import { useForm } from 'react-hook-form';

import {
    IonItem,
    IonLabel
} from '@ionic/react';

import ProgramCard from '../components/blocks/ProgramCard';
import ProgramDetail from '../components/blocks/ProgramDetail';
import {
    BmiSteps,
    HealthCondition
} from '../constants/Health';
import { FormStepProps } from '../inferfaces/FormStep';
import { ConsultationInputs } from '../models/Consultation';
import { ProgramId } from '../models/ProgramId';
import { bmi } from '../util';

const steps: {
	[key: number]: {
		title: string;
		color: string;
	};
} = {
	10: {
		title: 'Không được giảm cân bằng bất kỳ cách nào khi:',
		color: 'text-error-900',
	},
	9: {
		title: 'Liên hệ với công ty để có chương trình riêng cho người:',
		color: 'text-error',
	},
	8: {
		title: 'Theo dõi thuốc điều trị trong quá trình giảm cân',
		color: 'text-primary',
	},
};

const Advice: React.FC<{
	step: number;
	conditions: HealthCondition[];
}> = ({ step, conditions }) =>
	steps[step] ? (
		<div className='mt-2'>
			<IonItem
				className={clsx('text-lg font-medium font-heading ', steps[step].color)}
				lines='none'
			>
				{steps[step as any].title}
			</IonItem>
			{conditions.map((v, i) => (
				<IonItem key={i} lines='none'>
					<IonLabel className='ion-text-wrap'>
						<div>{v.title}</div>
						<p className='text-dark-secondary'>{v.note}</p>
					</IonLabel>
				</IonItem>
			))}
		</div>
	) : (
		<div>
			<IonItem
				className='text-lg font-medium font-heading text-info'
				lines='none'
			>
				Step {step}
			</IonItem>
			{conditions.map((v, i) => (
				<IonItem key={i}>{v.title}</IonItem>
			))}
		</div>
	);

type ProgramFormProps = FormStepProps<Partial<ConsultationInputs>>;
export const ProgramForm: React.FC<ProgramFormProps> = ({
	values,
	footer,
	onSubmit,
}) => {
	const { setValue, watch, handleSubmit } = useForm<
		Partial<ConsultationInputs>
	>({
		mode: 'onTouched',
		defaultValues: { programId: values.programId },
	});
	const bmiCondition =
		BmiSteps[values.gender || 'F'][
			_.sortedIndexBy(
				BmiSteps[values.gender || 'F'],
				{
					bmi: bmi(values.height || 160, values.weight || 60) || 0,
					step: 1,
					id: '',
					title: '',
				},
				'bmi'
			) - 1
		];

	const conditions = [...(values.conditions || []), bmiCondition];
	const conditionGroups = _.groupBy(conditions || [], 'step');
	const maxStep = _.maxBy(conditions, 'step')?.step || 1;

	const Container: React.FC<PropsWithChildren<{}>> = ({ children }) => (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className=''>{children}</div>
			{footer({
				nextState: !!watch('programId'),
				prevState: !!watch('programId'),
			})}
		</form>
	);
	const Inner: React.FC = () => {
		switch (maxStep) {
			case 10:
				return (
					<div className='section'>
						<Advice step={maxStep} conditions={conditionGroups[maxStep]} />
					</div>
				);
			case 9:
				return (
					<div className='section'>
						<Advice step={maxStep} conditions={conditionGroups[maxStep]} />
					</div>
				);
			case 5:
				return watch('programId') ? (
					<div className='section'>
						<ProgramDetail
							program={watch('programId') as string}
							loss={values.loss || 0}
							onSelect={() => setValue('programId', undefined)}
						/>
					</div>
				) : (
					<>
						{(['5'] as Array<ProgramId>).map((i) => (
							<ProgramCard
								program={i}
								key={i}
								loss={values.loss || 0}
								onSelect={(v) => setValue('programId', v)}
							/>
						))}
					</>
				);
			case 4:
				return watch('programId') ? (
					<div className='section'>
						<ProgramDetail
							program={watch('programId') as string}
							loss={values.loss || 0}
							onSelect={() => setValue('programId', undefined)}
						/>
					</div>
				) : (
					<>
						{(['4', '5'] as Array<ProgramId>).map((i) => (
							<ProgramCard
								program={i}
								key={i}
								loss={values.loss || 0}
								onSelect={(v) => setValue('programId', v)}
							/>
						))}
					</>
				);
			case 3:
				return watch('programId') ? (
					<div className='section'>
						<ProgramDetail
							program={watch('programId') as string}
							loss={values.loss || 0}
							onSelect={() => setValue('programId', undefined)}
						/>
					</div>
				) : (
					<>
						{(['3', '4'] as Array<ProgramId>).map((i) => (
							<ProgramCard
								program={i}
								key={i}
								loss={values.loss || 0}
								onSelect={(v) => setValue('programId', v)}
							/>
						))}
					</>
				);
			case 2:
				return watch('programId') ? (
					<div className='section'>
						<ProgramDetail
							program={watch('programId') as string}
							loss={values.loss || 0}
							onSelect={() => setValue('programId', undefined)}
						/>
					</div>
				) : (
					<>
						{(['2', '3'] as Array<ProgramId>).map((i) => (
							<ProgramCard
								program={i}
								key={i}
								loss={values.loss || 0}
								onSelect={(v) => setValue('programId', v)}
							/>
						))}
					</>
				);
			case 1:
				return watch('programId') ? (
					<div className='section'>
						<ProgramDetail
							program={watch('programId') as string}
							loss={values.loss || 0}
							onSelect={() => setValue('programId', undefined)}
						/>
					</div>
				) : (
					<>
						{(values.gender === 'F'
							? ['1A', '2', '3']
							: (['1B', '2', '3'] as Array<ProgramId>)
						).map((i) => (
							<ProgramCard
								program={i}
								key={i}
								loss={values.loss || 0}
								onSelect={(v) => setValue('programId', v)}
							/>
						))}
					</>
				);
			default:
				return <Container>{maxStep}</Container>;
		}
	};
	return (
		<form onSubmit={handleSubmit(onSubmit)} className='max-w-md mx-auto'>
			<Inner />
			{footer({
				nextState: !!watch('programId'),
				prevState: true,
			})}
		</form>
	);
};

export default ProgramForm;
