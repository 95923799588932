// import './NumberInput.scss';

import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IonRange } from '@ionic/react';
import {
    mdiMinus,
    mdiPlus
} from '@mdi/js';

import { IconButton } from './IconButton';

interface NumberInputProps {
	type?: string;
	label?: string;
	slot?: string;
	value?: number;
	min?: number;
	max?: number;
	name: string;
	onChange: (...event: any[]) => void;
	onBlur: () => void;
	className?: string;
}

export const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>(
	(
		{ name, onChange, onBlur, value, min = 0, max = 100, className, label },
		ref
	) => {
		const { t } = useTranslation('translation', { keyPrefix: 'field' });
		const [inFocus, setFocus] = useState(false);
		return (
			<div
				className={clsx(
					'flex flex-col justify-between text-sm text-center pt-1',
					className
				)}
			>
				<div className='mb-1 text-dark-secondary'>{t(label || name)}</div>
				<div className='flex items-center '>
					<IconButton
						disabled={value ? value <= min : false}
						icon={mdiMinus}
						onClick={(e) => {
							e.preventDefault();
							onChange(value && value > min ? value - 1 : min);
						}}
						className={clsx(
							'text-dark-secondary bg-transparent',
							value && value <= min ? ' opacity-disabled' : 'ripple-bg-gray-50'
						)}
					/>
					<input
						type='number'
						inputMode='numeric'
						className='p-0 text-4xl text-center bg-transparent border-0 shadow-none focus:border-0 focus:ring-0'
						ref={ref}
						name={name}
						value={value || ''}
						onChange={(e) => {
							onChange(Number(e.currentTarget.value || 0));
						}}
						onBlur={onBlur}
					/>

					<IconButton
						icon={mdiPlus}
						className={clsx(
							'text-dark-secondary bg-transparent',
							value && value >= max ? ' opacity-disabled' : 'ripple-bg-gray-50'
						)}
						onClick={(e) => {
							e.preventDefault();
							onChange(!value || value < min ? min + 1 : value + 1);
						}}
						disabled={value ? value >= max : false}
					/>
				</div>
				<IonRange
					value={value}
					max={max}
					min={min}
					disabled={max <= min}
					className='px-2 py-0 -mt-2'
					// @ts-ignore
					onIonChange={(e) => {
						if (inFocus) {
							onChange(Number(e.detail.value));
						}
					}}
					onIonFocus={() => {
						setFocus(true);
					}}
					onIonBlur={() => {
						onBlur();
						setFocus(false);
					}}
					onClick={(e) => {
						//@ts-ignore
						onChange(e.nativeEvent.target.value);
					}}
					name={name}
					mode='md'
				/>
			</div>
		);
	}
);
