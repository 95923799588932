import clsx from 'clsx';
import { PropsWithChildren } from 'react';

type InfoBlockProps = {
	title?: string;
	content?: string | number;
	subtitle?: string;
	textColor?: string;
	bgColor?: string;
	className?: string;
	unit?: string;
	disabled?: boolean;
};
const InfoBlock: React.FC<PropsWithChildren<InfoBlockProps>> = ({
	title,
	subtitle,
	disabled,
	children,
	textColor = 'text-black',
	bgColor = 'bg-white',
	className,
	content,
	unit,
}) => (
	<div
		className={clsx(
			className,
			'flex flex-col items-center rounded py-1.5 ',
			bgColor && bgColor,
			disabled && 'bg-opacity-disabled'
		)}
	>
		<div
			className={clsx(
				'text-sm',
				textColor,
				disabled ? 'text-opacity-disabled' : 'text-opacity-sedondary'
			)}
		>
			{title}
		</div>
		<div className='pt-1'>
			<span
				className={clsx(
					'text-4xl',
					textColor,
					disabled && 'text-opacity-disabled'
				)}
			>
				{content && content}
				{children}
			</span>
			{!disabled && unit && (content || children) && (
				<span
					className={clsx('ml-1 text-lg text-opacity-secondary', textColor)}
				>
					{unit}
				</span>
			)}
		</div>
		{subtitle && (
			<div
				className={clsx(
					'text-opacity-sedondary text-sm',
					textColor || 'text-black'
				)}
			>
				{subtitle}
			</div>
		)}
	</div>
);

export default InfoBlock;
