export type Step = 1 | 2 | 3 | 4 | 5 | 9 | 10;

export type HealthCondition = {
	id: string;
	title: string;
	note?: string;
	step: Step;
	monitor?: boolean;
};
export interface HealthGroup {
	title: string;
	conditions: HealthCondition[];
}

export type BmiStep = {
	id: string;
	bmi: number;
	step: Step;
	title: string;
};
export const BmiSteps: {
	M: BmiStep[];
	F: BmiStep[];
} = {
	M: [
		{ id: 'M00', bmi: 0, step: 10, title: 'BMI <19' },
		{ id: 'M19', bmi: 19, step: 2, title: 'BMI <23' },
		{ id: 'M23', bmi: 23, step: 1, title: 'BMI <23 (nam)' },
		{ id: 'M40', bmi: 40, step: 3, title: 'BMI 40 - 49,9 (nam)' },
		{ id: 'M50', bmi: 50, step: 4, title: 'BMI 50 - 59,9 (nam)' },
		{ id: 'M60', bmi: 60, step: 9, title: 'BMI > 60' },
	],
	F: [
		{ id: 'F00', bmi: 0, step: 10, title: 'BMI <19' },
		{ id: 'F19', bmi: 19, step: 2, title: 'BMI <23' },
		{ id: 'F23', bmi: 23, step: 1, title: 'BMI' },
		{ id: 'F40', bmi: 40, step: 2, title: 'BMI 40 - 49,9 (nữ)' },
		{ id: 'F50', bmi: 50, step: 3, title: 'BMI 50 - 59,9 (nữ)' },
		{ id: 'F60', bmi: 60, step: 9, title: 'BMI > 60' },
	],
};

export const womanHealth: HealthGroup = {
	title: 'Sinh sản',
	conditions: [
		{
			id: 'W01',
			title: 'Mong muốn có thai',
			note: 'Giảm cân sẽ giúp dễ mang thai hơn. Nhưng cần lưu ý dừng giảm cân ngay khi có mang.',
			step: 1,
		},
		{
			id: 'W02',
			title: 'Đang mang thai',
			note: 'Giảm cân ảnh hưởng tới sự phát triển của thai nhi.',
			step: 10,
		},
		{
			id: 'W03',
			title: 'Đang cho con bú',
			note: 'Việc giảm cân sẽ làm hạn chế lượng sữa mẹ, vì vậy không giảm cân trong thời gian cho con bú. Có thể bắt đầu chương trình giảm cân khi cai sữa cho bé.',
			step: 10,
		},
		{
			id: 'W04',
			title: 'Sinh con trong vòng 3 tháng',
			note: 'Nếu bạn không cho bé bú thì có thể giảm cân sau khi sinh được hơn 3 tháng.',
			step: 10,
		},
	],
};

export const healthGroups: HealthGroup[] = [
	{
		title: 'Tim mạch',
		conditions: [
			{
				id: 'TM1',
				title: 'Cao huyết áp',
				note: 'Giảm cân sẽ giúp ổn định chỉ số huyết áp.',
				step: 1,
				monitor: true,
			},
			{
				id: 'TM2',
				title: 'Đột quỵ trong 3 tháng gần đây',
				note: 'Chờ qua 3 tháng từ khi đột quỵ.',
				step: 10,
			},
			{
				id: 'TM3',
				title: 'Cơn đau thắt ngực',
				step: 3,
			},
			{
				id: 'TM4',
				title: 'Cơn đau tim trong 3 tháng gần đây',
				note: 'Chờ qua 3 tháng kể từ ngày có cơn đau tim gần nhất.',
				step: 10,
			},
			{
				id: 'TM5',
				title: 'Loạn nhịp tim',
				step: 4,
			},
			{ id: 'TM6', title: 'Tất cả bệnh lý tim (trừ loạn nhịp)', step: 9 },
			{
				id: 'TM7',
				title: 'Thuốc điều chỉnh lipid/rối loạn mỡ máu',
				step: 1,
				monitor: true,
			},
			{ id: 'TM8', title: 'Thiếu máu/hồng cầu', step: 2 },
			{ id: 'TM9', title: 'Dùng thuốc kháng đông', step: 9 },
		],
	},
	{
		title: 'Nội tiết',
		conditions: [
			{
				id: 'NT1',
				title: 'Tiểu đường điều trị chế độ ăn hay Metformin',
				note: 'Nghiên cứu khoa học cho thấy đường huyết có thể được kiểm soát sau khi giảm 10% trọng lượng cơ thể.',
				step: 1,
				monitor: true,
			},
			{
				id: 'NT2',

				title: 'Tiểu đường type 1 điều trị bằng thuốc',
				step: 9,
			},
			{
				id: 'NT3',
				title: 'Tiểu đường type 2 điều trị bằng thuốc',
				step: 9,
			},
			{
				id: 'NT4',

				title: 'Đái tháo nhạt',
				step: 2,
			},
			{
				id: 'NT5',

				title: 'Gout',
				step: 2,
			},
		],
	},
	{
		title: 'Tiêu hoá ',
		conditions: [
			{
				id: 'TH1',
				title: 'Loét dạ dày / bao tử',
				step: 3,
			},
			{
				id: 'TH2',

				title: 'Phẫu thuật cắt dạ dày',
				step: 9,
			},
			{ id: 'TH3', title: 'Bệnh gan/suy gan', step: 9 },
			{ id: 'TH4', title: 'Bệnh thận/suy thận', step: 9 },
			{
				id: 'TH5',

				title: 'Sỏi thận',
				step: 3,
			},
			{
				id: 'TH6',

				title: 'Sỏi túi mật',
				step: 2,
			},
			{
				id: 'TH7',

				title: 'Viêm loét đại tràng',
				step: 2,
			},
			{
				id: 'TH8',

				title: 'Bệnh chi nang đại tràng',
				step: 2,
			},
			{
				id: 'TH9',

				title: 'Táo bón',
				step: 2,
			},
			{
				id: 'TH10',
				title: 'Bệnh phàm ăn/chán ăn',
				step: 9,
			},
		],
	},

	{
		title: 'Đang điều trị / Đang dùng thuốc',
		conditions: [
			{
				id: 'DT1',

				title: 'Thuốc kháng sinh',
				step: 2,
			},

			{
				id: 'DT2',

				title: 'Điều trị ưng thư',
				note: 'Hãy hoàn thành việc điều trị ưng thư trước khi giảm cân.',
				step: 10,
			},
			{
				id: 'DT3',

				title: 'Điều trị tuyến giáp',
				step: 1,
				monitor: true,
			},
			{
				id: 'DT4',

				title: 'Thuốc giảm cân/chống béo phì',
				note: 'Không được giảm cân khi dùng thuốc giảm cân/chống béo phì.',
				step: 10,
			},
			{
				id: 'DT5',

				title: 'Thuốc giảm đau trung bình/mạnh',
				step: 2,
			},
			{ id: 'DT6', title: 'Điều trị vẩy nến', step: 2 },
			{
				id: 'DT7',
				title: 'Điều trị thấp khớp  bằng thuốc',
				step: 2,
			},
			{
				id: 'DT8',

				title: 'Thuốc lợi tiểu',
				step: 1,
				monitor: true,
			},

			{
				id: 'DT9',

				title: 'Thuốc cai thuốc lá Champix',
				step: 3,
			},
			{
				id: 'DT10',
				title: 'Thuốc ức chế MAOI',
				step: 10,
			},
		],
	},
	{
		title: 'Khác',
		conditions: [
			{
				id: 'KH1',
				title: 'Chóng mặt',
				step: 2,
			},
			{
				id: 'KH2',
				title: 'Phẫu thuật/chuẩn bị phẫu thuật',
				step: 9,
			},
			{ id: 'KH3', title: 'Bệnh lý/chấn thương tuỷ sống', step: 9 },
			{ id: 'KH4', title: 'Nghiện rượu', step: 10 },
			{ id: 'KH5', title: 'Lạm dụng thuốc', step: 10 },
			{ id: 'KH6', title: 'Rối loạn thần kinh/cơ', step: 9 },
			{ id: 'KH7', title: 'Rối loạn tâm thần', step: 9 },
			{ id: 'KH8', title: 'Động kinh', step: 9 },
			{ id: 'KH9', title: 'Bệnh Crohn', step: 2 },
			{ id: 'KH10', title: 'Porphyria', step: 9 },
		],
	},
];
