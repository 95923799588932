export const bmi = (height: number, weight: number): number | undefined => {
	if (typeof weight !== 'number' || typeof height !== 'number' || weight === 0)
		return undefined;
	const b = Math.round((weight * 100000) / height / height) / 10;

	return b > 100 || b < 10 ? undefined : b;
};

export const fromBmi = (height: number, bmi: number) =>
	Math.round((bmi * height * height) / 10000);
export const bmiLevel = (bmi: number, gender?: 'M' | 'F') =>
	bmi >= 30
		? 'obese2'
		: bmi >= 28
		? 'obese1'
		: bmi >= 25
		? 'pre-obese'
		: bmi >= 23
		? 'overweight'
		: gender === 'M'
		? bmi >= 20
			? 'man-normal'
			: bmi >= 18.5
			? 'man-thin'
			: 'underweight'
		: bmi >= 21
		? 'overshape'
		: bmi >= 19.5
		? 'idea'
		: bmi >= 18.5
		? 'woman-thin'
		: 'underweight';

export const bmr = ({
	weight,
	height,
	age,
	gender,
}: {
	weight: number;
	height: number;
	age: number;
	gender: 'F' | 'M';
}) =>
	(gender !== 'F' && gender !== 'M') ||
	height < 120 ||
	height >= 200 ||
	weight <= 20 ||
	weight >= 200 ||
	age > 100
		? undefined
		: gender === 'F'
		? Math.round(10 * weight + 6.25 * height - 5 * age - 161)
		: Math.round(10 * weight + 6.25 * height - 5 * age + 5);

// Revised Harris-Benedict Equation:
// ? Math.round(447.593 + 9.247 * weight + 3.098 * height - 4.33 * age)
// : Math.round(88.362 + 13.397 * weight + 4.799 * height - 5.677 * age);

// If you are sedentary (little or no exercise)
// Calories Per Day = BMR x 1.2
// If you are lightly active (light exercise or sports 1-3 days/week)
// Calories Per Day = BMR x 1.375
// If you are moderately active (moderate exercise 3-5 days/week)
// Calories Per Day = BMR x 1.55
// If you are very active (hard exercise 6-7 days/week)
// Calories Per Day = BMR x 1.725
// If you are super active (very hard exercise and a physical job)
// Calories Per Day = BMR x 1.9
