import clsx from 'clsx';
import { chevronBack } from 'ionicons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    IonButton,
    IonIcon
} from '@ionic/react';
import {
    mdiAlert,
    mdiCheckboxMarkedOutline,
    mdiCloseCircle
} from '@mdi/js';

import { Programs } from '../../constants';
import { ProgramId } from '../../models/ProgramId';
import Icon from '../controls/Icon';
import { ProgramInfo } from './ProgramInfo';
import { ProgramMeal } from './ProgramMeal';

const SeverityIcons = {
	high: mdiCloseCircle,
	medium: mdiAlert,
	normal: mdiCheckboxMarkedOutline,
};
const groupTitleClass = 'group-header bg-white border-b mt-4';

export const ProgramDetail: React.FC<{
	program: keyof typeof Programs;
	loss: number;
	onSelect?: (v: ProgramId) => void;
}> = ({ program: p, loss, onSelect }) => {
	const program = Programs[p];
	const { t } = useTranslation();
	return (
		<>
			<div
				className={clsx(
					program.color,
					'sticky top-0 z-10 py-3 bg-white section-header border-dark-dividers'
				)}
			>
				Bước {p}
			</div>
			<ProgramInfo program={program} loss={loss} />
			<div>
				{program.preparation && (
					<>
						<div className={clsx(groupTitleClass, 'top-14')}>
							Giai đoạn chuẩn bị
						</div>
						<div className='border-b section-content'>
							{program.preparation.map((t, i) => (
								<div key={i} className='flex '>
									<div className='text-dark-secondary min-w-[64px]'>{`Ngày ${
										i + 1
									}:`}</div>
									{t}
								</div>
							))}
						</div>
					</>
				)}
				<>
					<div className={clsx(groupTitleClass, 'top-24')}>
						Tuần đầu Giảm cân
					</div>
					<div className='border-b section-content'>
						<div className='pb-1 text-dark-secondary'>
							{`Ngày ${
								program.preparation ? program.preparation.length + 1 : 1
							} - ngày ${
								program.preparation ? program.preparation.length + 7 : 7
							}`}
						</div>
						<ProgramMeal program={program} />
					</div>
				</>

				{program.notes && (
					<>
						<div className={clsx(groupTitleClass, 'top-34')}>Lưu ý</div>
						<div className='section-footer'>
							{program.notes.map((n, i) => (
								<div
									key={i}
									className={clsx(
										'flex  py-1',
										n.severity === 'high' && 'text-error',
										n.severity === 'medium' && 'text-danger-700',
										n.severity === 'normal' && 'text-success-600'
									)}
								>
									<Icon
										path={SeverityIcons[n.severity]}
										size={1}
										className='mr-4'
									/>
									{n.text}
								</div>
							))}
						</div>
					</>
				)}
			</div>
			{onSelect && (
				<>
					<hr />
					<div className='border-b section-content'>
						<IonButton onClick={() => onSelect(p as string)} color='light'>
							<IonIcon icon={chevronBack} slot='start' />
							{t('Select Other')}
						</IonButton>
					</div>
				</>
			)}
		</>
	);
};
export default ProgramDetail;
