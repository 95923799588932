import { Program } from '../models/Program';

export const Programs: { [key: string]: Program } = {
	'1A': {
		kcal: 600,
		sachets: 3,

		min: 2,
		max: 3,
		adj: 0.5,
		color: 'text-pink',
		preparation: [
			'1 gói + 2 bữa ăn cân bằng',
			'2 gói + 1 bữa ăn cân bằng',
			'2 gói + 1 bữa ăn cân bằng',
		],
		notes: [
			{
				severity: 'high',
				text: 'Không ăn tất cả các loại trái cây',
			},
			{
				severity: 'medium',
				text: 'Không ăn đồ ngọt, tinh bột & các loại củ',
			},
			{
				severity: 'medium',
				text: 'Không uống rượu bia, nước ngọt',
			},
			{
				severity: 'normal',
				text: 'Uống đủ 2,5 lít nước mỗi ngày',
			},
		],
	},
	'1B': {
		kcal: 800,
		sachets: 4,
		min: 2,
		max: 3,
		adj: 0.5,
		color: 'text-pink',
		preparation: [
			'1 gói + 2 bữa ăn cân bằng',
			'2 gói + 1 bữa ăn cân bằng',
			'2 gói + 1 bữa ăn cân bằng',
		],
		notes: [
			{
				severity: 'high',
				text: 'Không ăn tất cả các loại trái cây',
			},
			{
				severity: 'medium',
				text: 'Không ăn đồ ngọt, tinh bột & các loại củ',
			},
			{
				severity: 'medium',
				text: 'Không uống rượu bia, nước ngọt',
			},
			{
				severity: 'normal',
				text: 'Uống đủ 2,5 lít nước mỗi ngày',
			},
		],
	},
	'1A + 2': {
		kcal: 800,
		sachets: 3,
		min: 1.5,
		max: 2,
		adj: 0,
		color: 'text-primary',
		notes: [
			{
				severity: 'high',
				text: 'Không ăn tất cả các loại trái cây',
			},
			{
				severity: 'medium',
				text: 'Không ăn đồ ngọt, tinh bột & các loại củ',
			},
			{
				severity: 'medium',
				text: 'Không uống rượu bia, nước ngọt',
			},
			{
				severity: 'normal',
				text: 'Uống đủ 2,5 lít nước mỗi ngày',
			},
		],
	},
	'2': {
		sachets: 3,
		kcal: 800,
		min: 1.5,
		max: 2,
		adj: 0.5,
		color: 'text-green',
		smallMeal: 1,
		preparation: [
			'1 gói + 2 bữa ăn cân bằng',
			'2 gói + 1 bữa ăn cân bằng',
			'2 gói + 1 bữa ăn cân bằng',
		],
		notes: [
			{
				severity: 'high',
				text: 'Không ăn tất cả các loại trái cây',
			},
			{
				severity: 'medium',
				text: 'Không ăn đồ ngọt, tinh bột & các loại củ',
			},
			{
				severity: 'medium',
				text: 'Không uống rượu bia, nước ngọt',
			},
			{
				severity: 'normal',
				text: 'Uống đủ 2,5 lít nước mỗi ngày',
			},
		],
	},
	'3': {
		sachets: 2,
		balancedMeal: 1,
		kcal: 1000,
		min: 1,
		max: 1.5,
		adj: 0,
		color: 'text-blue',
		preparation: ['1 gói + 2 bữa ăn cân bằng'],
		notes: [
			{
				severity: 'medium',
				text: 'Hạn chế ăn trái cây',
			},
			{
				severity: 'medium',
				text: 'Hạn chế đồ ngọt, tinh bột & các loại củ',
			},
			{
				severity: 'medium',
				text: 'Hạn chế rượu bia, nước ngọt',
			},
			{
				severity: 'normal',
				text: 'Uống đủ 2,5 lít nước mỗi ngày',
			},
		],
	},
	'4': {
		sachets: 2,
		smallMeal: 1,
		balancedMeal: 2,
		kcal: 1200,
		min: 0.5,
		max: 1,
		adj: 0,
		color: 'text-orange',
		preparation: ['1 gói + 2 bữa ăn cân bằng'],
		notes: [
			{
				severity: 'normal',
				text: 'Uống đủ 2,5 lít nước mỗi ngày',
			},
		],
	},
	'5': {
		sachets: 1,
		kcal: 1500,
		balancedMeal: 2,
		min: 0.2,
		max: 0.3,
		adj: 0,
		color: 'text-red',
		notes: [
			{
				severity: 'normal',
				text: 'Uống đủ 2,5 lít nước mỗi ngày',
			},
		],
	},
};
