import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    mdiHumanFemale,
    mdiHumanMale
} from '@mdi/js';

import Icon from './Icon';

interface GenderSelectProps {
	label?: string;
	onChange: (...event: any[]) => void;
	onBlur: () => void;
	value: 'M' | 'F';
	name: string;
	className?: string;
}
export const GenderSelect = React.forwardRef<
	HTMLInputElement,
	GenderSelectProps
>(({ value, onChange, className, onBlur }, ref) => {
	const { t } = useTranslation();
	return (
		<div className={clsx('grid w-full grid-cols-2 gap-1', className)}>
			<button
				className={clsx(
					'flex flex-col items-center py-1 gap-2 text-sm text-center rounded',
					value === 'F'
						? 'ripple-bg-pink-500  text-light-primary '
						: 'ripple-bg-pink-100 text-dark-disabled'
				)}
				disabled={value === 'F'}
				onClick={() => {
					onBlur();
					onChange('F');
				}}
			>
				{t('field.female')}
				<Icon path={mdiHumanFemale} size={2} />
			</button>
			<button
				className={clsx(
					'flex flex-col items-center py-1 gap-2 text-sm text-center rounded ',
					value === 'M'
						? ' text-light-primary ripple-bg-blue-500'
						: ' text-dark-disabled ripple-bg-blue-100'
				)}
				onClick={() => {
					onBlur();
					onChange('M');
				}}
				disabled={value === 'M'}
			>
				{t('field.male')}

				<Icon path={mdiHumanMale} size={2} />
			</button>
		</div>
	);
});
