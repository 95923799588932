import { forwardRef } from 'react';
import {
    Control,
    Controller,
    useForm
} from 'react-hook-form';

import {
    IonCheckbox,
    IonItem,
    IonLabel
} from '@ionic/react';

import {
    HealthCondition,
    HealthGroup,
    healthGroups,
    womanHealth
} from '../constants/Health';
import { FormStepProps } from '../inferfaces/FormStep';
import { ConsultationInputs } from '../models/Consultation';

type HealthItemProps = {
	condition: HealthCondition;
	checked?: boolean;
	onChange: (...event: any[]) => void;
	onBlur?: () => void;
};
const HealthItem: React.FC<HealthItemProps> = ({
	condition,
	checked,
	onBlur,
	onChange,
}) => (
	<IonItem lines='none'>
		<IonCheckbox
			slot='start'
			checked={checked}
			onIonChange={onChange}
			onIonBlur={onBlur}
			mode='md'
		/>
		<div>{condition.title}</div>
	</IonItem>
);

const Group: React.FC<{
	group: HealthGroup;
	// name: string;
	control: Control;
}> = forwardRef(({ group, control }, ref) => (
	<>
		<div className='group-header '>
			<IonLabel className='test'>{group.title}</IonLabel>
		</div>

		<div slot='content' className='grid grid-flow-row md:grid-cols-2 '>
			{group.conditions.map((c, i) => (
				<Controller
					name={c.id}
					control={control}
					key={i}
					render={({ field: { value, onChange } }) => (
						<HealthItem
							condition={c}
							checked={value}
							onChange={(e) => {
								onChange(e.detail.checked ? c : undefined);
							}}
						/>
					)}
				/>
			))}
		</div>
	</>
));
const toConditions = (con: { [key: string]: HealthCondition }) =>
	Object.keys(con).reduce((r, k) => {
		if (con[k]) {
			r.push(con[k]);
		}
		return r;
	}, [] as HealthCondition[]);

const fromConditions = (conditions: HealthCondition[]) =>
	conditions.reduce((r, c) => {
		r[c.id] = c;
		return r;
	}, {} as { [key: string]: HealthCondition });

type MedicalFormProps = FormStepProps<Partial<ConsultationInputs>>;

const MedicalForm: React.FC<MedicalFormProps> = ({
	onSubmit,
	footer,
	values: { conditions, gender, age },
}) => {
	// const { t } = useTranslation();
	const {
		control,
		handleSubmit,
		formState: { isValid },
	} = useForm({
		defaultValues: fromConditions(conditions || []),
	});
	return (
		<form
			onSubmit={handleSubmit((c) => onSubmit({ conditions: toConditions(c) }))}
		>
			<div className='mt-0 mb-0'>
				{gender === 'F' && age && age > 20 && age < 45 && (
					<Group group={womanHealth} control={control} />
				)}
				{healthGroups.map((g, i) => (
					<Group group={g} control={control} key={i} />
				))}
			</div>
			{footer({ nextState: isValid, prevState: isValid })}
		</form>
	);
};

export default MedicalForm;
