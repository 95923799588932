import clsx from 'clsx';
import React from 'react';

import Icon from './Icon';

interface IconButtonProps {
	onClick: React.MouseEventHandler<HTMLButtonElement>;
	icon: string;
	className?: string;
	disabled?: boolean;
}
export const IconButton: React.FC<IconButtonProps> = ({
	onClick,
	icon,
	className,
	disabled,
}) => (
	<button
		onClick={onClick}
		className={clsx(
			'relative p-1 rounded-full focus:outline-none',
			className,
			disabled && 'disabled'
		)}
		disabled={disabled}
	>
		<Icon path={icon} className='block' />
		{/* {!disabled && <IonRippleEffect />} */}
	</button>
);
