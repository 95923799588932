import React from 'react';
import {
    Controller,
    useForm
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IonList } from '@ionic/react';

import BmiBlock from '../components/blocks/BmiBlock';
import InfoBlock from '../components/blocks/InfoBlock';
import {
    GenderSelect,
    Input,
    NumberInput
} from '../components/controls';
import { FormStepProps } from '../inferfaces/FormStep';
import { ConsultationInputs } from '../models/Consultation';
import {
    bmi,
    bmr,
    fromBmi
} from '../util/bmi';

interface PreconsultationFormProps
	extends FormStepProps<Partial<ConsultationInputs>> {}
const PreconsultationForm: React.FC<PreconsultationFormProps> = ({
	values,
	onSubmit,
	footer,
}) => {
	const { t } = useTranslation();
	const { watch, control, handleSubmit, formState } =
		useForm<ConsultationInputs>({
			mode: 'onTouched',
			defaultValues: values,
		});
	return (
		<form onSubmit={handleSubmit(onSubmit)} className='max-w-md mx-auto'>
			<div className='p-4 '>
				<IonList className='pb-4 pr-4 mt-0 mb-2 bg-white border rounded border-dark-dividers '>
					<Input
						name='fullname'
						control={control}
						position='floating'
						lines='inset'
						rules={{
							required: 'required',
						}}
					/>
					<Input
						name='phone'
						control={control}
						position='floating'
						lines='inset'
						rules={{
							required: 'required',
						}}
					/>
				</IonList>
				<div className='flex gap-2'>
					<div className='flex flex-col gap-2'>
						<Controller
							control={control}
							name='age'
							rules={{ required: true }}
							render={({ field }) => (
								<NumberInput
									{...field}
									min={10}
									max={100}
									className='h-24 px-2 bg-white border rounded border-dark-dividers'
								/>
							)}
						/>
						<Controller
							control={control}
							name='height'
							render={({ field }) => (
								<NumberInput
									{...field}
									min={120}
									max={200}
									className='h-24 px-2 bg-white border rounded border-dark-dividers'
								/>
							)}
						/>
						<Controller
							control={control}
							name='weight'
							render={({ field }) => (
								<NumberInput
									{...field}
									min={40}
									max={150}
									className='h-24 px-2 bg-white border rounded border-dark-dividers'
								/>
							)}
						/>
						<Controller
							control={control}
							name='loss'
							rules={{ required: true, min: 1 }}
							render={({ field }) => (
								<NumberInput
									{...field}
									min={0}
									max={Math.max(
										watch('weight') -
											fromBmi(
												watch('height'),
												watch('gender') === 'M' ? 20 : 19
											),
										0
									)}
									className='h-24 px-2 bg-white border rounded border-dark-dividers'
								/>
							)}
						/>
					</div>
					<div className='flex flex-col flex-shrink-0 gap-2 w-28 md:w-32'>
						<Controller
							control={control}
							name='gender'
							rules={{ required: true }}
							render={({ field }) => (
								<GenderSelect {...field} className='h-24' />
							)}
						/>

						<InfoBlock
							title={t('BMR')}
							className='h-24 py-2 text-sm text-dark-primary font-body'
							bgColor='bg-secondary'
						>
							{bmr(watch())}
						</InfoBlock>

						<BmiBlock
							bmi={bmi(watch('height'), watch('weight'))}
							gender={watch('gender')}
							className='h-24 py-2'
							title='BMI - Present'
						/>
						<BmiBlock
							bmi={bmi(watch('height'), watch('weight') - watch('loss') || 0)}
							className='h-24 py-2 rounded '
							gender={watch('gender')}
							title='BMI - After'
						/>
					</div>
				</div>
			</div>
			{footer({ nextState: formState.isValid, prevState: formState.isValid })}
		</form>
	);
};

export default PreconsultationForm;
