import { useTranslation } from 'react-i18next';

import { bmiLevel } from '../../util/bmi';
import InfoBlock from './InfoBlock';

const bgColors = {
	'obese2': 'bg-error-800',
	'obese1': 'bg-error-600',
	'pre-obese': 'bg-error-400',
	'overweight': 'bg-danger-700',
	'overshape': 'bg-danger-500',
	'man-normal': 'bg-success-500',
	'idea': 'bg-success-500',
	'man-thin': 'bg-danger-400',
	'woman-thin': 'bg-success-400',
	'underweight': 'bg-danger-600',
};
const textColors = {
	'obese2': 'text-white',
	'obese1': 'text-white ',
	'pre-obese': 'text-white',
	'overweight': 'text-white',
	'overshape': 'text-black',
	'man-normal': 'text-black',
	'idea': 'text-black',
	'woman-thin': 'text-black',
	'man-thin': 'text-black',
	'underweight': 'text-black',
};

export const BmiBlock: React.FC<{
	bmi?: number;
	gender?: 'M' | 'F';
	className?: string;
	title?: string;
	subtitle?: string;
	content?: string | number;
	unit?: string;
}> = ({ bmi, className, title, gender, content, subtitle, unit }) => {
	const { t } = useTranslation();
	return (
		<InfoBlock
			title={t(title || 'BMI')}
			className={className}
			subtitle={
				subtitle || (bmi ? t(`bmi.${bmiLevel(bmi, gender)}`) : undefined)
			}
			bgColor={bmi ? bgColors[bmiLevel(bmi, gender)] : 'bg-gray-300'}
			textColor={bmi ? textColors[bmiLevel(bmi, gender)] : undefined}
			disabled={!bmi}
			unit={unit}
		>
			{content || bmi}
		</InfoBlock>
	);
};

export default BmiBlock;
