import { Program } from '../../models/Program';

type ProgramInfoProps = {
	loss: number;
	program: Program;
};
export const ProgramInfo: React.FC<ProgramInfoProps> = ({ loss, program }) => (
	<div className='flex justify-between section-content'>
		<div className='flex flex-col items-center'>
			<div className='text-3xl text-danger-800 font-medium'>
				{Math.ceil(loss / program.max)}-{Math.ceil(loss / program.min)}
			</div>
			<div className='text-dark-secondary'>tuần</div>
		</div>
		<div className='flex flex-col items-center '>
			<div className='text-3xl text-blue-800 font-medium'>
				{Math.ceil((loss / program.max) * program.sachets * 0.7 + program.adj)}-
				{Math.ceil((loss / program.min) * program.sachets * 0.7 + program.adj)}
			</div>
			<div className='text-dark-secondary '>Cambridge</div>
		</div>
		<div className='flex flex-col items-center min-w-10'>
			<div className='text-3xl text-green-800 font-medium'>
				{Math.ceil(loss / (program.max + program.min) / 2)}
			</div>
			<div className='text-dark-secondary'>KM</div>
		</div>
	</div>
);
