import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    mdiHumanFemale,
    mdiHumanMale,
    mdiHumanMaleFemale
} from '@mdi/js';

import Icon from '../controls/Icon';
import InfoBlock from './InfoBlock';

interface GenderBlockProps {
	title?: string;
	value?: 'M' | 'F';
	className?: string;
}

const GenderBlock: React.FC<GenderBlockProps> = ({
	className,
	title,
	value,
}) => {
	const { t } = useTranslation();
	return (
		<InfoBlock
			bgColor={
				value === 'F'
					? 'bg-pink-500  '
					: value === 'M'
					? 'bg-blue-500'
					: 'bg-opacity-disabled bg-white'
			}
			textColor={value ? 'text-light-primary' : 'text-dark-disabled'}
			className={className}
			title={value === 'M' ? t('Male') : value === 'F' ? t('Female') : title}
		>
			{value === 'F' ? (
				<Icon path={mdiHumanFemale} size={2} />
			) : value === 'M' ? (
				<Icon path={mdiHumanMale} size={2} />
			) : (
				<Icon path={mdiHumanMaleFemale} size={2} />
			)}
		</InfoBlock>
	);
};

export default GenderBlock;
