import './ConsultationPage.css';

import clsx from 'clsx';
import {
    arrowBack,
    arrowForward
} from 'ionicons/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar
} from '@ionic/react';

import { HealthCondition } from '../constants/Health';
import ConsultationSummary from '../forms/ConsultationSummary';
import MedicalForm from '../forms/MedicalForm';
import PreconsultationForm from '../forms/PreconsultationForm';
import ProgramForm from '../forms/ProgramForm';
import { FormStepProps } from '../inferfaces/FormStep';
import { Gender } from '../models/Gender';
import { ProgramId } from '../models/ProgramId';

// import { useTranslation } from 'react-i18next';
interface ConsultationInputs {
	fullname: string;
	phone: string;
	gender: Gender;
	age: number;
	height: number;
	weight: number;
	loss: number;
	conditions: HealthCondition[];
	programId: ProgramId;
}

const steps: Array<{
	label: string;
	Component: React.FC<FormStepProps<Partial<ConsultationInputs>>>;
}> = [
	{
		label: 'Preconsultation',
		Component: PreconsultationForm,
	},
	{
		label: 'Health',
		Component: MedicalForm,
	},
	{
		label: 'Summary',
		Component: ConsultationSummary,
	},
	{
		label: 'Program',
		Component: ProgramForm,
	},
	{
		label: 'Tư vấn',
		Component: ConsultationSummary,
	},
];

const ConsultationPage: React.FC = () => {
	const [active, setActive] = useState<number>(0);
	const [consultation, setConsultation] = useState<Partial<ConsultationInputs>>(
		{
			conditions: [],
			height: 160,
			weight: 60,
			age: 40,
		}
	);

	const { t } = useTranslation();
	const handleSubmit = (newConsultation: Partial<ConsultationInputs>) => {
		setConsultation({ ...consultation, ...newConsultation });
	};

	const handleNext = () => {
		if (active < steps.length - 1) {
			setActive(active + 1);
		}
	};
	const handlePrev = () => {
		if (active > 0) {
			setActive(active - 1);
		}
	};
	const footer = ({
		nextState,
		prevState,
	}: {
		nextState: boolean;
		prevState: boolean;
	}) => (
		<div
			className={clsx(
				'flex p-2 md:mb-2',
				active === 0 ? 'justify-end' : 'justify-between'
			)}
		>
			{active > 0 && (
				<IonButtons slot='start'>
					<IonButton
						mode='md'
						type='submit'
						onClick={handlePrev}
						color={prevState ? 'secondary' : 'dark'}
						disabled={!prevState}
					>
						<IonIcon icon={arrowBack} slot='start' />
						{t(steps[active - 1].label)}
					</IonButton>
				</IonButtons>
			)}
			{
				<IonButtons slot='end'>
					<IonButton
						type='submit'
						mode='md'
						onClick={handleNext}
						color={nextState ? 'highlight' : 'dark'}
						disabled={!nextState || active === steps.length - 1}
						// fill='solid'
					>
						{active < steps.length - 1
							? t(steps[active + 1].label)
							: t('Submit')}
						<IonIcon icon={arrowForward} slot='end' />
					</IonButton>
				</IonButtons>
			}
		</div>
	);
	return (
		<IonPage>
			<IonHeader className=' shadow-1 ion-no-border'>
				<IonToolbar>
					<IonButtons slot='start'>
						<IonMenuButton />
					</IonButtons>
					<IonTitle>
						{t(steps[active].label)}
						{consultation.fullname && ` - ${consultation.fullname}`}
					</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				{steps.map(
					({ Component }, i) =>
						active === i && (
							<Component
								values={consultation}
								footer={footer}
								onSubmit={handleSubmit}
								key={i}
							/>
						)
				)}
			</IonContent>
		</IonPage>
	);
};

export default ConsultationPage;
