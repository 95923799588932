import React from 'react';
import { useTranslation } from 'react-i18next';

import BmiBlock from '../components/blocks/BmiBlock';
import GenderBlock from '../components/blocks/GenderBlock';
import InfoBlock from '../components/blocks/InfoBlock';
import ProgramDetail from '../components/blocks/ProgramDetail';
import { FormStepProps } from '../inferfaces/FormStep';
import { ConsultationInputs } from '../models/Consultation';
import { bmi } from '../util';

type ConsultationSummaryProps = FormStepProps<Partial<ConsultationInputs>>;

export const ConsultationSummary: React.FC<ConsultationSummaryProps> = ({
	values: { age, weight, height, gender, loss, conditions, programId },
	footer,
}) => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'field',
	});
	const blockClassName = 'border';
	return (
		<div className='max-w-md mx-auto '>
			<div className=' section'>
				<div className='section-header'>{t('Health Index')}</div>
				<div className='grid grid-cols-3 grid-rows-2 gap-2 p-3 section-content'>
					<InfoBlock
						title={t('height')}
						unit='cm'
						className={blockClassName}
						content={height}
					/>
					<InfoBlock
						content={age}
						title={t('age')}
						className={blockClassName}
					/>
					<GenderBlock
						value={gender}
						title={t('gender')}
						className={blockClassName}
					/>
					<BmiBlock
						content={weight}
						title={t('weight')}
						subtitle={`BMI: ${bmi(height || 0, weight || 0)}`}
						bmi={bmi(height || 0, weight || 0)}
						unit='kg'
						className={blockClassName}
					/>
					<InfoBlock
						content={loss}
						title={t('loss')}
						className={blockClassName}
						bgColor={loss ? 'bg-primary' : 'bg-white'}
						textColor={loss ? 'text-white' : 'text-black'}
						unit='kg'
					/>
					<BmiBlock
						content={(weight || 0) - (loss || 0)}
						title={t('after-loss')}
						subtitle={`BMI: ${bmi(height || 0, (weight || 0) - (loss || 0))}`}
						bmi={bmi(height || 0, (weight || 0) - (loss || 0))}
						unit='kg'
						className={blockClassName}
					/>
				</div>
				<div className='section-content'>
					<div className='group-header px-0 bg-transparent section-content border-b-0'>
						Tình trạng sức khoẻ hiện tại
					</div>
					{conditions && conditions?.length > 0 ? (
						<div>
							{conditions.map((v) => (
								<div key={v.id} className='py-2'>
									{v.title}
								</div>
							))}
						</div>
					) : (
						<div className='leading-7'>
							<div>Hoàn toàn khoẻ mạnh</div>
							<div>Không trong giai đoạn điều trị bệnh</div>
							<div>Không sử dụng thuốc</div>
						</div>
					)}
				</div>
				{programId && (
					<div className=''>
						<ProgramDetail program={programId} loss={loss || 0} />
					</div>
				)}
			</div>
			{footer({ nextState: true, prevState: true })}
		</div>
	);
};

export default ConsultationSummary;
