import React from 'react';
import {
    Controller,
    ControllerProps,
    FieldValues
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Components } from '@ionic/core';
import {
    IonInput,
    IonItem,
    IonLabel
} from '@ionic/react';

interface InputProps<TFieldValues extends FieldValues = FieldValues>
	extends Omit<ControllerProps<TFieldValues>, 'render'>,
		Partial<Omit<Components.IonInput, 'name'>>,
		Partial<Omit<Components.IonItem, 'type' | 'disabled'>> {
	label?: string;
	position?: 'fixed' | 'floating' | 'stacked';
}

export function Input<TFieldValues extends FieldValues = FieldValues>({
	position,
	name,
	type,
	label,
	control,
	lines,
	...rest
}: InputProps<TFieldValues>) {
	const { t } = useTranslation('translation', { keyPrefix: 'field' });
	return (
		<Controller
			control={control}
			name={name}
			{...rest}
			render={({ field, fieldState: { error } }) => (
				<IonItem lines={lines}>
					<IonLabel position={position} color={error && 'danger'}>
						{t(label || name)}
						{error && error.message && (
							<span className='italic'>{` (${t(error.message)})`}</span>
						)}
					</IonLabel>
					<IonInput
						onIonChange={field.onChange}
						onIonBlur={field.onBlur}
						type={type}
						value={field.value}
					/>
				</IonItem>
			)}
		/>
	);
}
