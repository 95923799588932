import { useTranslation } from 'react-i18next';

import { Program } from '../../models/Program';

type ProgramMealProps = {
	program: Program;
};
export const ProgramMeal: React.FC<ProgramMealProps> = ({ program }) => {
	const { t } = useTranslation();
	return program.balancedMeal || program.smallMeal ? (
		<ul className='list-disc list-inside ml-1'>
			<li className='leading-7'>{`${program.sachets} ${t('sachet')}`}</li>
			{program.smallMeal && (
				<li className='leading-7'>{`${program.smallMeal} ${t(
					'small meal'
				)}`}</li>
			)}
			{program.balancedMeal && (
				<li className='leading-7'>{`${program.balancedMeal} ${t(
					'balanced meal'
				)}`}</li>
			)}
		</ul>
	) : (
		<div className='leading-7 '>{`${program.sachets} ${t('sachet')}`}</div>
	);
};
