import React from 'react';

import { IonApp } from '@ionic/react';

import ConsultationPage from './pages/ConsultationPage';

const App: React.FC = () => {
	return <IonicApp />;
};

interface IonicAppProps {}

const IonicApp: React.FC<IonicAppProps> = () => {
	return (
		<IonApp>
			<ConsultationPage />
		</IonApp>
	);
};

export default App;
